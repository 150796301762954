$border_color: 1px solid #ddd;
$border-top: 2px solid #ddd;
$bg: #fff;
$bg1: #888;
$theme_color: var(--primary-color);
$theme_red_color: var(--accent-color);
$theme_black_color: #000;
$theme_green_color: #098a1f;
$box_shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
$box_shadow1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
0px 1px 1px 0px rgba(0, 0, 0, 0.14),
0px 1px 3px 0px rgba(0, 0, 0, 0.12);
$box_shadow3: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
$theme_text_color: white;
$pixel15: 15px;
$pixel1: 1px;
$padding_tb0lr10: 0px 10px;
$pading_tbl10r0: 0.625em 0em 0.625em 0.625em;
$bdrclr1: #ddd;
$bgrcrl2: #eee;
$bdrclr3: #f0f0f0;
$bdrclr4: #f5f5f5;
$highligh: #f5faff;
$backgray: #f9f9f9;
$border-bw1: 12px;
$border-bw2: 5px;
$padding_1: 16px 0px 16px 0px;
$padding_2: 0px 10px 0px 20px;
$padding_3: 15px 10px 15px 10px;
$padding_4: 0px 10px 0px 10px;
$padding_5: 10px 38px 10px 38px;
$padding_6: 0px 0px 20px 0px;
$padding_7: 12px 0px;
$padding_8: 5px 20px;
$padding_9: 30px 0px 10px 0px;
$padding_10: 0 16px;
$padding_11: 10px 0px 10px 10px;
$padding_13: 13px 15px 13px 30px;
$padding_14: 10px 20px 10px 20px;
$padding_15: 10px 10px 10px 0px;
$padding_16: 10px 0px;
$padding_18: 15px 0px;
$padding_17: 10px 10px 5px 10px;
$pixel10: 10px;
$pixel20: 20px;
$pixel5: 5px;
$pixel15: 15px;
$pixel16: 16px;
$font-size16: 16px;
$font-size18: 18px;
$font-size20: 20px;
$font-size14: 14px;
$font-size11: 11px;
$font-size12: 12px;
$font-size22: 22px;
$font-size32: 32px;
$font-weight500: 500;
$font-weight400: 400;
$font_weight600: 600;
//divider

.white{
    color:#ffffff
}
.divider {
    border-top: 1px solid rgba(204, 204, 204, 0.6);
}


.disable-pointer{
    pointer-events: none;
}

.color-gray {
    color: #878787
}

//margin
.m-0 {
    margin: 0 !important;
}

//pading-0
.p-0 {
    padding: 0 !important;
}

//padding RightLeft

  .padRL50{
    padding: 0px 50px;
  }

//font initial

.fSize{
    font-size: initial;
  }

//padding 10
.padding-10 {
    padding: $pixel10;
}

.padding-tb10 {
    padding: 10px 0px;
}

.padding-t10 {
    padding-top: $pixel10;
}

.padding-b10 {
    padding-bottom: $pixel10;
}

.padding-l10 {
    padding-left: $pixel10;
}

.padding-2 {
    padding: 2px;
}

.border {
    border: $border_color;
}

.padding-t50{
    padding-top: 50px;
}

.padding-3 {
    padding: 3px;
}

.padding-t3 {
    padding-top: 3px;
}

.padding-5 {
    padding: 5px;
}

.padding-7 {
    padding: 7px;
}

.padding-8 {
    padding: 8px;
}

.padding-l5 {
    padding-left: $pixel5;
}

.padding-r30 {
    padding-right: 30px;
}



.padding-160 {
    padding: 16px 0px;
}

.padding-r5 {
    padding-right: $pixel5;
}

.padding-r10 {
    padding-right: $pixel10;
}

.padding-t5 {
    padding-top: $pixel5;
}

.padding-b5 {
    padding-bottom: $pixel5;
}

.padding-15 {
    padding: $pixel15;
}

.padding-t30 {
    padding-top: 30px;
}

.padding-l15 {
    padding-left: $pixel15;
}

.padding-r15 {
    padding-right: $pixel15;
}

.padding-l10t30 {
    padding: 30px 0px 0px 10px;
}

.padding-t15 {
    padding-top: $pixel15;
}

.padding-b15 {
    padding-bottom: $pixel15;
}

.padding-16 {
    padding: 16px;
}

.padding-l16 {
    padding-left: 16px;
}

.padding-r16 {
    padding-right: 16px;
}

.padding-r10 {
    padding-right: $pixel10;
}

.padding-20 {
    padding: $pixel20;
}

.padding-l20 {
    padding-left: $pixel20;
}

.padding-l60 {
    padding-left: 60px;
}

.padding-t20 {
    padding-top: $pixel20;
}

.padding-b10 {
    padding-bottom: $pixel10;
}

.borderb {
    border-bottom: $border_color;
}

.borderb2 {
    border-top: $border-top;
}

.bordert {
    border-top: $border_color;
}

.border-bottom {
    border-bottom: 2px solid #ddd;
}

.border-btm {
    border-bottom: 1px solid #ddd;
}

.borderl {
    border-left: $border_color;
}

.padding-30 {
    padding: 30px;
}

.padding-l30 {
    padding-left: 30px;
}

.padding-r20 {
    padding-right: $pixel20;
}

.padding-td10 {
    padding: 10px 0px;
}

.backg {
    background-color: $bg;
}

.clrbg{
    color: $bg;
}

.padding-t35 {
    padding-top: 35px;
}

.padding-b35 {
    padding-bottom: 35px;
}

.padding-39 {
    padding: 39px;
}

.padding-l40 {
    padding-left: 40px;
}

.padding-bottom {
    padding-bottom: 60px;
}

.padding-b42 {
    padding-bottom: 42px;
}

.padding-50 {
    padding: 50px;
}

.padding-l50 {
    padding-left: 50px;
}

.borderBottom {
    border-bottom: 3px solid #f1f3f6;
}

.boxShadow {
    box-shadow: $box_shadow3;
}

//sticky mobile_toolbar
.font16 {
    font-size: 16px;
}

.f14 {
    font-size: 14px;
}

// margin
.margin-tb10 {
    margin: 20% 0%;
}


  .margin-t10 {
    margin-top: var(--homepage-layout-space);
  }

// margin
.margin-t20 {
    margin-top:$pixel20; 
}


.sticky {
    position: fixed;
    z-index: 1000;
    width: 102%;
}

.stickyFilter {
    @extend .sticky;
    background-color: $bg;
}



.cdk-global-scrollblock {
    left: 0px;
    top: 0px;
    background-color: $bg;
}

.borderdark {
    border-bottom: 6px solid $bgrcrl2;
}

.backgray {
    background-color: $backgray;
}

.bordert3 {
    border-top: 3px solid $bdrclr1;
}

.colorRed {
    color: var(--accent-color);
    padding: 6px;
    font-size: 11px;
    font-weight: 600;
}

.zIndex {
    z-index: 10;
}

.success {
    color: $theme_green_color;
}

.faliure {
    color: var(--accent-color);
}

.blackColor {
    color: $theme_black_color;
}

.faliure-bg {
    background-color: var(--accent-color);
}

.theme-color {
    background-color: $theme_color;
}

.theme-clr {
    color: $theme_color;
}

.time-pad {
    padding: 12px 24px;
}

.icon_color {
    color: $bg;
}

.icon_color1 {
    color: #000;
}

.font-weight-500 {
    font-weight: $font-weight500;
}

.bg-color {
    background-color: $bg;
}

.font-22px {
    font-size: $font-size22;
}

.font-20px {
    font-size: $pixel20;
}

.font-16px {
    font-size: $font-size16;
}

.ptd15 {
    padding: 15px 0px;
}

.font18px {
    font-size: $font-size18;
}

.font-primary-color {
    color: $theme_color;
}

.pointer {
    cursor: pointer;
}

.background {
    background-color: $bg;
}

.positionFix {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background: $bg;
    width: 100% !important;
}

.social-icon {
    width: 48px;
    height: 48px;
    cursor: pointer;
    fill: #8f8f8f;
    outline: none;
}

.stickyBottom {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background: $bg;
    width: 100% !important;
}

//cart page start
#cart {
    @media (min-width: 992px) {
        .scroll {
            .element::-webkit-scrollbar {
                width: 0 !important;
            }
            max-height: auto;
        }
    }
    @media (min-width: 576px) and (max-width: 767px) {
        .scroll {
            .element::-webkit-scrollbar {
                width: 0 !important;
            }
            max-height: auto;
        }
    }
    .price {
        .head {
            border-bottom: $border_color;
        }
        .grandTotal {
            border-top: $border_color;
        }
    }
    .imgQty {
        padding-top: 16px;
        padding-right: 10px;
        img {
            max-width: 104px;
        }
        .controls {
            padding: 10px 0px 10px 0px;
        }
    }
    #emptyCart {
        padding: $padding_6;
        background: $bg;
        .cart {
            padding: $padding_7;
        }
        .borderb {
            border-bottom: 3px solid $bdrclr1;
        }
        .cartImg {
            img {
                max-width: 250px;
            }
        }
        .cartEmpty {
            padding: $padding_18;
            span {
                font-size: $font-size18;
                color: $theme_text_color;
            }
        }
        .shop {
            cursor: pointer;
            user-select: none;
            border: $border_color;
            box-shadow: $box_shadow;
            span {
                color: $theme_text_color;
                font-size: $font-size14;
            }
        }
    }
    .cart {
        .cartDetails {
            background: white;
            box-shadow: $box_shadow;
            .cardHead {
                border-bottom: $border_color;
                padding: 18px 10px 12px 0px;
                h1 {
                    padding-left: 20px;
                    font-size: 18px;
                }
                .removeAll {
                    user-select: none;
                    cursor: pointer;
                    padding: 5px 10px 5px 10px;
                    border: 1px solid var(--accent-color);
                    color: var(--accent-color);
                }
            }
            .cartProductDetails {
                padding: 24px 0px 10px 0px;
                span {
                    font-size: 16px;
                    color: $theme_text_color;
                }
            }
            .basePrice {
                span {
                    font-size: 20px;
                    color: var(--accent-color);
                }
            }
            .placeOrder {
                border-top: $border_color;
                background: $bg;
                padding: 10px 22px;
                box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.1);
                cursor: pointer;
                user-select: none;
                .cnfrmOrder {
                    background: var(--accent-color);
                    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
                    border: none;
                    color: $bg;
                    padding: 12px;
                    span {
                        color: white;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }
        .cartDetails1 {
            background: white;
            //box-shadow: $box_shadow;
            .cardHead {
                border-bottom: $border_color;
                padding: 18px 10px 12px 0px;
                h1 {
                    padding-left: 20px;
                }
                .removeAll {
                    user-select: none;
                    cursor: pointer;
                    padding: 5px 10px 5px 10px;
                    border: 1px solid var(--accent-color);
                    color: var(--accent-color);
                }
            }
            .cartProductDetails {
                padding: 24px 0px 10px 0px;
                span {
                    font-size: 16px;
                    color: $theme_text_color;
                }
            }
            .basePrice {
                span {
                    font-size: 20px;
                    color: var(--accent-color);
                }
            }
            .placeOrder {
                border-top: $border_color;
                background: $bg;
                padding: 10px 22px;
                box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.1);
                cursor: pointer;
                user-select: none;
                .cnfrmOrder {
                    background: var(--accent-color);
                    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
                    border: none;
                    color: $bg;
                    padding: 12px;
                    span {
                        color: white;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }

    }
    .shop {
        cursor: pointer;
        user-select: none;
        background: var(--accent-color);
        padding: 10px 0px 10px 0px;
        span {
            color: $bg;
            font-size: 14px;
        }
    }
    .cartRm {
        padding-top: 20px;
        padding-bottom: 20px;
        .removeCart {
            user-select: none;
            cursor: pointer;
            padding: 5px 10px 5px 10px;
            border: $border_color;
            span {
                color: $theme_text_color;
            }
        }
        .removeCart:hover {
            user-select: none;
            cursor: pointer;
            padding: 5px 10px 5px 10px;
            border: 1px solid var(--accent-color);
            span {
                color: var(--accent-color);
            }
        }
    }
}

// cart end
// address start
#address {
    .header1 {
        min-height: 64px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        border: $border_color;
        font-size: $font-size14;
        color: $theme_color;
        font-weight: $font-weight500;
        cursor: pointer;
        .title {
            .extra {
                font-size: $font-size12;
                color: $bg1;
            }
        }
    }
    .highlighted {
        background-color: $bdrclr4;
    }
    @media (min-width: 992px) {
        .padding-all10 {
            padding: 10px;
        }
    }
    @media (min-width: 576px) and (max-width: 767px) {
        .padding-all10 {
            padding: 0px;
        }
    }
}

// address end
//dashboad account
#dashboard {
    @media (min-width: 992px) {
        .padding-lr10 {
            padding: 0px;
        }
    }
    @media (min-width: 576px) and (max-width: 767px) {
        .padding-lr10 {
            padding: 0px 10px;
        }
    }
}

/// elevation
@media (min-width: 992px) {
    .product-elevation {
        background: hsla(360, 100%, 100%, 1);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        padding: 7px;
    }
    .product-carousel {
        background: hsla(360, 100%, 100%, 1);
        padding: 7px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .product-elevation {
        background: hsla(360, 100%, 100%, 1);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        padding: 10px;
    }
}

.product-carousel:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    padding: 7px;
}

.product-elevation2 {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    background: hsla(360, 100%, 100%, 1);
    padding: 0px;
    width: 100%;
}

/// elevation end
// controls start
.qty {
    .quantity {
        .inc {
            padding: 0px 4px;
            border: $border_color;
            cursor: pointer;
            user-select: none;
            background: $bg;
        }
        .qty {
            padding: 0px 4px;
            border-top: $border_color;
            border-bottom: $border_color;
        }
        .dec {
            @extend .inc;
        }
    }
}

.mat-icon-button.mat-small {
    min-width: 1%;
}

@media (min-width: 768px) {
    .buyCart {
        padding: 25px 0px;
        .cart {
            cursor: pointer;
            background: var(--primary-color);
            i {
                color: $bg;
            }
            span {
                color: $bg;
                font-size: $pixel15;
                user-select: none;
                //font-family: "font-regular";
            }
        }
        .buy {
            cursor: pointer;
            background: var(--accent-color);
            i {
                color: $bg;
            }
            span {
                color: $bg;
                font-size: $pixel15;
                user-select: none;
                //font-family: "font-regular";
            }
        }
    }
}

@media (min-width: 0px) and (max-width: 767px) {
    .buyCart {
        .cart {
            cursor: pointer;
            background: var(--primary-color);

            i {
                color: $bg;
            }
            span {
                color: $bg;
                font-size: $pixel15;
                user-select: none;
                //font-family: "font-regular";
            }
        }
        .buy {
            cursor: pointer;
            background: var(--accent-color);
            i {
                color: $bg;
            }
            span {
                color: $bg;
                font-size: $pixel15;
                user-select: none;
                //font-family: "font-regular";
            }
        }
    }
}

// controls end
/// prooduct Page
#productsPage {
    .filter-sidenav {
        width: 280px;
        border: 0 !important;
        .mat-expansion-panel-header-title {
            text-transform: uppercase;
        }
    }
    .filter-row {
        background: $bg;
        padding: 8px 12px;
    }
    .all-products {
        min-height: 400px;
        overflow: hidden;
        background: #fafafa;
        &.p-left {
            //background: $bg;
        }
    }
    .products-wrapper {
        //background: $bg;
        margin: 8px -8px;
        @media (min-width: 992px) {
            .col {
                padding: 1px;
                background-color: #fafafa;
            }
        }
        @media (min-width: 576px) and (max-width: 767px) {
            .col {
                padding: 0px;
            }
        }
        .col1 {
            padding: 1px;
        }
    }
    .categories-wrapper {
        position: relative;
        max-height: 300px;
    }
    .filter-buttons {
        button {
            min-width: 36px;
            margin: 0 6px 10px 0;
            padding: 0;
        }
    }
    .filter-price {
        .mat-slider-horizontal {
            min-width: 110px;
        }
    }
    .filter-brands {
        button {
            width: 66px;
            height: 44px;
            min-width: 66px;
            margin: 0 6px 10px 0;
            padding: 0px;
            overflow: hidden;
            img {
                width: 66px;
                height: 44px;
            }
        }
    }
    // .filtersticky {
    //   position: fixed;
    //   z-index: 899;
    //   width: 102%;
    //   background-color: $bg;
    // }
    .filterSort {
        border-bottom: $border_color;
        position: relative;
        overflow: hidden;
        font-weight: bold;
        display: flex;
    }
    .filterSort1 {
        border-left: $border_color;
        @extend .filterSort;
    }
    @media (min-width: 766px) and (max-width: 992px) {
        .filterProduct {
            padding: 10px 0px 10px 0px;
        }
    }
     ::ng-deep .ps--active-x>.ps__rail-x {
        display: none;
    }
    .search-results {
        overflow-y: scroll;
    }
    .overlayProduct {
     //   background-color: $bg;
        /*  top: 0px;
  left: 0px; */
      
        /* in FireFox */
       
        /* in IE */
        // pointer-events: none;
        // cursor: not-allowed;
    }
    .container {
        position: relative;
        text-align: center;
    }
    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .outof {
        background: $bg;
    }
}

//product page end
// product Details
#productDetails {
    .mat-card.product-image {
        padding: 6px;
        text-align: center;
        button {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 99;
        }
        // img {
        //     height: 412px;
        //     max-width: 100%;
        // }
        .mat-chip-list {
            position: absolute;
            top: 10px;
            left: 10px;
            pointer-events: none;
        }
    }
    .prod {
        z-index: -10;
    }
    // .small-carousel {
    //   margin-top: 8px;
    //   .swiper-container {
    //     //padding: 2px;
    //     .swiper-slide {
    //       text-align: center;
    //       img {
    //         max-width: 100%;
    //       }
    //     }
    //   }
    //   .swiper-button-next.swiper-button-disabled,
    //   .swiper-button-prev.swiper-button-disabled {
    //     opacity: 0;
    //   }
    //   .swiper-button-prev {
    //     left: -14px;
    //     color: $theme_black_color;
    //   }
    //   .swiper-button-next {
    //     right: -10px;
    //   }
    // }
    .mat-card.zoom-viewer {
        position: absolute;
        display: none;
        background-repeat: no-repeat;
        //padding: 8px;
        z-index: 99;
    }
    @media (min-width: 576px) and (max-width: 767px) {
        .product-mobile {
            border: 1px solid #eee;
            background: #fff;
        }
    }
    .mt-16 {
        margin-top: 16px;
    }
    .details button {
        padding: 0;
        min-width: 36px;
        margin-left: 6px;
    }
    .mat-list.reviews {
        .mat-list-item .mat-list-avatar.review-author {
            width: 80px;
            height: 80px;
        }
        .mat-list-item .mat-line.text {
            white-space: unset;
            font-style: italic;
            margin: 10px 0;
        }
    }
    .full-desc {
        padding: 30px 40px;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    th,
    td {
        text-align: left;
        padding: 8px;
    }
    tr:nth-child(even) {
        background-color: #ddd;
    }
    .recent {
        @extend .pd;
    }
    .pd {
        background: $bg;
        box-shadow: $box_shadow3;
    }
    .product-details {
        padding-right: 2px solid $theme_black_color;
        .bigImage {
            padding-top: $pixel10;
        }
    }
    .imgContant {
        padding: $padding_4;
    }
    .imagesPro {
        border-bottom: 1px solid #ddd;
        cursor: pointer;
        z-index: 10;
    }
    .imagesProduct:hover {
        border: 2px solid var(--primary-color);
        border-color: var(--primary-color);
    }
    .productName {
        padding: 0px 0px 10px 10px;
        .name {
            color: $theme_black_color;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-height: 27px;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: $font-size20;
        }
    }
    .prot {
        .emi {
            color: $theme_black_color;
        }
        span {
            font-size: $font-size16;
            color: $theme_black_color;
            small {
                padding-left: $pixel5;
            }
        }
        .product-Price {
            font-size: $font-size18;
            color: var(--accent-color);
            span {

                cursor: pointer;
                z-index: 10;
                color: $theme_green_color;

            }
        }
    }
    .brand {
        border: 1px solid $bdrclr1;
        cursor: text;
        z-index: 10;
      
        background: $bg;
        p {
            padding: $padding_4;
        }
    }
    .highlight {
        padding: 10px 0px 10px 25px;
    }
    ul .list {
        list-style-type: circle;
    }
    .bg-white {
        background: $bg;
    }
    // img {
    //   height: auto;
    //   max-width: 100%;
    //   vertical-align: middle;
    // }
    $color: $theme_text_color;
    $bdrc: #ddd;
    $border: 1px solid $bdrc;
    .stock {
        .outStock {
            border: $bdrc;
            background: var(--accent-color);
            color: $bg;
        }
        .inStock {
            @extend .inStock;
            background: $theme_green_color;
            color: $bg;
        }
    }
    .pDetails {
        background: $bg;
        border-bottom: $border;
        .details {
            padding: $padding_15;
        }
        .img1 {
            img {
                height: 230px;
            }
        }
    }
    .btmimg {
        .bottomImg {
            border: $border;
        }
    }
    .alld {
        padding: $padding_16;
        span {
            font-weight: $font_weight600;
        }
        .allB {
            background: $bg;
        }
    }
    .mIcon {
        transform: rotateY(180deg);
    }
    .pName {
        padding: $padding_4;
        span {
            font-size: 14px;
            color: $bg1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .pPrice {
        padding: $padding_17;
        .new-price {
            font-weight: $font-weight400;
            font-size: $pixel20;
            color: var(--accent-color);
        }
        .regPrice {
            font-size: 13px;
            color: $bg1;
        }
        .discount {
            @extend .regPrice;
            color: var(--accent-color);
        }
    }
    .tag {
        padding: 0px 10px 5px 10px;
    }
    .share {
        border-top: $border;
        border-bottom: $border;
        padding: $padding_16;
        .mat-icon {
            color: $bg1;
        }
        span {
            font-size: $pixel20;
            color: $bg1;
        }
    }
    .similarProducts {
        //padding: 10px;
        border-bottom: $border;
        border-top: $border;
        span {
            color: $bg1;
        }
        mat-icon {
            @extend span;
        }
    }
    .detail-content {
        padding: 0px;
    }
    .positionFix {
        position: fixed;
        bottom: 0;
        z-index: 1000;
        background: $bg;
        width: 100% !important;
    }
    .emi {
        span {
            color: $theme_color;
        }
    }
    .full-width-dialog .mat-dialog-container {
        max-width: 100vw !important;
    }
}

// product details page end
// login page start
.mat-dialog-container {
    padding: 0px !important;
    border-radius: 0px;
    background-color: $bg;
}

.left {
    background: $theme_color;
    padding: 40px 35px;
    .login {
        span {
            font-size: x-large;
            font-weight: $font-weight500;
            color: $bg;
        }
        .msg {
            p {
                font-size: 15px;
                margin-top: $pixel16;
                line-height: 150%;
                color:$bg;
            }
        }
    }
}

.img1 {
    img {
        max-width: 200px;
    }
}

.signform {
    padding: 0px;
    .fields {
        padding: 0px 50px;
    }
}

.loginButton {
    user-select: none;
    cursor: pointer;
    padding: $pixel5;
    border-radius: $pixel5;
    background-color: $theme_color;
    span {
        color: $bg;
    }
}

.overlayProduct {
  
    /* in FireFox */
  
    /* in IE */
    // pointer-events: none;
    // cursor: not-allowed;
}

// login page end
//share
// #productBottomSheet {
//   .cancel {
//     // border: $border_color;
//     padding: 15px 0px 15px 0px;
//     span {
//       color: $theme_color;
//     }
//   }
//   .mat-bottom-sheet-container {
//     padding: 0px 0px;
//   }
//   .content {
//     background: $bg;
//     padding: 15px 20px 0px 20px;
//   }
//   .share1 {
//    // padding: 15px 0px 15px 20px;
//     border-bottom: $theme_color;
//     background: $theme_color;
//     span {
//       font-size: 18px;
//       color: $bg;
//     }
//   }
//   .icon {
//     span {
//       color: #000;
//     }
//   }
//   .click {
//     cursor: pointer;
//   }
// }
// user verify
.padding-3dr {
    padding: 0px 20px 20px 20px;
}

#userVerify {
    .border {
        border: $border_color;
    }
    .login {
        background-color: $theme_green_color;
        color: $bg;
        border-radius: 5px;
        cursor: pointer;
    }
}

//user verigy end
// checkout page start
.price {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0px;
    background: $bg;
    border-radius: 2px;
    min-height: 47px;
    box-shadow: $box_shadow;
    .priceDetails {
        padding: 12px 0px;
        background-color: $theme_color;
        color: $bg;
        span {
            font-size: $font-size16;
        }
    }
    .grandTotal {
        padding: 12px 0px 12px 0px;
        border-top: 1px solid $bgrcrl2;
        .amt {
            color: var(--accent-color);
        }
    }
}

.login {
    .contant1 {
        padding: 12px 24px;
        background: $theme_color;
        min-height: 48px;
        span {
            color: $bg;
            font-size: $pixel15;
            font-weight: $font-weight500;
            margin-bottom: 6px;
            text-transform: uppercase;
        }
    }
    .loginDetails {
      //  padding: 26px 20px 18px 50px;
        position: relative;
        .c50 {
            .name {
                color: $bg1;
                font-size: $pixel15;
                font-weight: $font-weight500;
                margin-bottom: 6px;
                text-transform: uppercase;
            }
            .login {
                color: $theme_color;
                user-select: none;
                cursor: pointer;
            }
            .checkoutContin {
                box-shadow: $box_shadow;
                border: none;
                color: $bg;
                background: var(--accent-color);
                padding: 10px 0px 10px 0px;
                font-weight: $font-weight500;
                user-select: none;
                text-transform: uppercase;
                font-size: $pixel16;
                cursor: pointer;
            }
        }
    }
    .loginDetails1 {
        position: relative;
    }
    .contant {
        padding: 7px 19px;
        min-height: 72px;
        .login {
            color: $bg1;
            font-size: $pixel15;
            font-weight: $font-weight500;
            margin-bottom: 6px;
            text-transform: uppercase;
        }
    }
}

.padding-520 {
    padding: 5px 20px;
}

.sticky1 {
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    z-index: 1000;
}

.positionFix {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background: $bg;
    width: 100% !important;
}

.continue1 {
    padding: 0px 10px 0px 10px;
    .order {
        background: var(--accent-color);
        span {
            font-size: $pixel16;
            color: $bg;
        }
    }
}

.payment {
    padding: 7px 19px;
    min-height: 45px;
}

.highlighted {
    background-color: $highligh;
}

// product all details start
.mat-dialog-container {
    padding: 0px !important;
}

$color-default-gray: #333e48;
.product-name1 {
    font-size: 1.7rem;
    font-weight: 500;
    margin-left: 8px;
    margin-bottom: 8px;
    color: $color-default-gray;
}

.title {
    color: $theme_black_color;
    font-size: 14px;
    font-family: var(--website-font-family);
    p {
        line-height: 1.5625em;
    }
}

.full-desc {
    padding: 30px 40px;
}

ng::deep.mat-tab-label,
ng:deep.mat-tab-label-active {
    min-width: 50% !important;
    //padding-left: 10px!important;
    //margin: 3px!important;
    border: $border_color;
}

.lh {
    line-height: 27px;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #fafafa;
}

.tdKey {
    color: #717171;
    padding: 6px;
}

.backk {
    background: $bg;
    padding: 0.40625em;
    img {
        width: 75px;
        height: 75px;
    }
}

// all product details end
// image preview
#imgPreview {
    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .mat-dialog-container {
        padding: 0px !important;
        border-radius: 0px;
    }
    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
        opacity: 0;
    }
    .swiper-button-prev {
        left: -14px;
        color: $theme_black_color;
    }
    .swiper-button-next {
        right: -10px;
    }
}

// product zoom start
.zoom-dialog {
    .mat-dialog-container {
        overflow: visible !important;
        .controls {
            position: relative;
            button {
                position: absolute;
                top: -44px;
            }
            .zoom-in {
                right: 44px;
            }
            .zoom-out {
                right: 0;
            }
            .close {
                right: -44px;
            }
        }
        .viewer {
            width: 100%;
            text-align: center;
            img {
                max-width: 60%;
            }
        }
    }
}

// product zoom
// Product Bottom Sheet start
.cancel {
    padding: $padding_18;
    span {
        color: $theme_color;
    }
}

.mat-bottom-sheet-container {
    padding: 0px 0px;
}

.content {
    background: $bg;
    padding: 15px 20px 0px 20px;
}

.share1 {
    padding: 15px 0px 15px 20px;
    border-bottom: $theme_color;
    background: $theme_color;
    span {
        font-size: 18px;
        color: $bg;
    }
}

// product Bottom Sheet end
// breadcrump
#breadcrump {
    .breadcrumb {
        padding: 8px 0;
        box-shadow: none;
        margin-bottom: 10px;
        a,
        span {
            font-size: 13px;
            text-decoration: none;
            color: inherit;
        }
        p {
            font-size: 13px;
            color: inherit;
            margin-top: 0px;
        }
        .mat-icon {
            font-size: 20px;

            width: 20px;
            padding: 0 6px;
            opacity: 0.7;
        }
        .breadcrumb-item+.breadcrumb-item:before {
            display: inline-block;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            content: "»";
        }
        .breadcrumb-item2+.breadcrumb-item2:before {
            display: inline-block;
            padding-right: 0.3rem;
            padding-left: 0.3rem;
            content: "/";
        }
        .breadcrumb-item3+.breadcrumb-item3:before {
            display: inline-block;
            padding-right: 0.4rem;
            padding-left: 0.4rem;
            content: ">";
            font-size: 20px;
            font-weight: 200;
        }

    }
}

/// breadcrump end
// pages start
#pages {
    @media (min-width: 992px) {
        .main {
            min-height: 400px;
            min-height: calc(100vh - 288px);
            padding: 8px 0px;
        }
    }
    @media (min-width: 576px) and (max-width: 767px) {
        .main {
            background: $bg;
        }
    }
    .sidenav {
        width: 250px;
        padding: 8px 16px;
        position: fixed;
        .close {
            margin-left: 178px;
        }
        .divider {
            margin: 8px 0;
        }
    }
    .cart-items-count {
        position: absolute;
        top: -3px;
        left: 26px;
        background: var(--accent-color);
        height: 18px;
        width: 18px;
        line-height: 18px;
        border-radius: 50%;
        font-size: 11px;
    }
    .search-v45 {
        input {
            background: $bg;
            font-size: 15px;
            width: 70%;
            height: 35px;
            color: $theme_black_color;
            padding-left: 6px;
        }
        input::-webkit-input-placeholder {
            color: $theme_black_color;
        }
        input:-moz-placeholder {
            color: $theme_black_color;
        }
        form {
            position: relative;
            &:after {
                font-family: "Material Icons";
                content: "search";
                color: $theme_black_color;
                background: $bg;
                z-index: 2;
                position: absolute;
                height: 30px;
                right: 5px;
                top: 2px;
                color: $theme_color;
                font-size: 26px;
            }
        }
    }
}

.order {
    background: $theme_color;
    color: $bg;
    cursor: pointer;
    user-select: none;
    box-shadow: $box_shadow1;
    border-radius: 3px;
}

.orredDate {
    span {
        padding: $padding_tb0lr10;
    }
}

.ratee {
    cursor: pointer;
    color: $theme_color;
}

.orderProduct {
    padding-top: 0.938em;
    .image {
        padding: $pading_tbl10r0;
    }
}

.delivery {
    padding-bottom: $pixel10;
}

//

/******* Product *******/

.product-item {
    position: relative;
    .mat-chip-list {
        position: absolute;
        top: 10px;
        right: 10px;
        &.list-view {
            left: 10px;
        }
    }
    .image-link {
        display: block;
    }
    // img {
    //     max-width: 100%;
    //     margin-bottom: 12px;
    // }
    .category {
        margin-bottom: 4px;
    }
    .title {
        display: block;
        color: inherit;
        text-decoration: none;
        font-weight: 500;
        font-size: 18px;
    }
    .prices {
        // margin: 12px 0;
        .old-price {
            text-decoration: line-through;
            height: 15px;
            //color: red;
        }
        .new-price {
            font-size: 16px;
            font-weight: 500;
        }
    }
    .icons {
        margin-top: 18px;
    }
}


.back {
    img {
      height: auto;
  vertical-align: middle;
  max-width: 100%;
    }
}

.bdr {
    border: $border_color;
}

.bdr-b {
    border-bottom: $border_color;
}

//catList
#catList {
    background-color: #ffff;
    .subchild {
        align-content: center;
        padding-left: 50px;
    }
    .child {
        border-left: $border_color;
    }
    .micon {
        color: black;
        font-size: 1em;
    }
    .search {
        font-size: 24px;
        //padding-left: 20px;
    }
    .itemLabel {
        font-size: 18px;
    }
    .iconClr {
        color: black;
    }
    .cName {
        align-content: center;
    }
    .cartbg {
        background-color: #ffff;
    }
}

//calsoft account
#calsoft-account {
    .account-sidenav {
        width: 280px;
        .mat-nav-list {
            box-sizing: border-box;
            .mat-list-item {
                height: 36px;
                font-size: $font-size14;
            }
        }
    }
    .account-content {
        min-height: 400px;
        overflow: hidden;
        &.p-left {
            background: $bg;
        }
        .header-row {
            background: $bg;
            padding: $padding_10;
            height: 56px;
        }
        .account-wrapper {
            margin-top: 16px;
            background: $bg;
        }
    }
    .accountSidenav {
        .profile {
            background: $bg;
            box-shadow: $box_shadow3;
            padding: $padding_11;
            .image {
                img {
                    max-width: 40px;
                }
            }
            .info {
                .info-1 {
                    font-size: $font-size12;
                }
                .info-2 {
                    font-size: $font-size12;
                    font-weight: $font-weight500;
                    text-transform: capitalize;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: #221122;
                }
            }
        }
    }
    .myOrder {
        cursor: pointer;
        .order {
            background: $bg;
            box-shadow: $box_shadow3;
            padding: $padding_13;
            span {
                width: calc(100% - 26px);
                padding-left: $pixel20;
                font-size: $font-size16;
                font-weight: $font-weight500;
                color: #878787;
                cursor: pointer;
            }
            span:hover {
                width: calc(100% - 26px);
                padding-left: $pixel20;
                font-size: $font-size16;
                font-weight: $font-weight500;
                color: $theme_color;
                cursor: pointer;
            }
        }
        .myOrd {
            background: $bg;
            box-shadow: $box_shadow3;
            padding: $padding_13;
        }
    }
    .navList {
        background: $bg;
        box-shadow: $box_shadow3;
    }
    .mat-list-base {
        padding: $padding_14;
        display: block;
        -webkit-tap-highlight-color: transparent;
    }
    .paddingDiv:focus {
        outline: 0;
    }
    .account-highlighted {
        background: #eeeeee;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        mat-icon {
            color: $theme_color;
        }
        a {
            @extend mat-icon;
        }
    }
    .nav-list:hover {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        background: hsla(0, 0%, 0%, 0.04);
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        mat-icon {
            color: $theme_color;
        }
        a {
            @extend mat-icon;
        }
    }
    .mat-nav-list.mat-focused {
        background-color: none;
    }
    .mat-list-base .mat-list-item,
    .mat-list-base .mat-list-option {
        display: block;
        height: 37px;
        -webkit-tap-highlight-color: transparent;
        width: 100%;
    }
    .right-contant {
        background: $bg;
        box-shadow: $box_shadow3;
    }
    .divider {
        background: $theme_color;
    }
    .account-mobile {
        background-color: $theme_color;
        img {
            margin-top: 20%;
        }
        .info {
            .info-1 {
                color: $bg;
            }
            .info-2 {
                color: $bg;
                font-weight: $font-weight500;
            }
        }
    }
    .content {
        background: $bg;
        .order {
            background: $bg;
        }
    }
}

//wishlist
#calsoft-wishlist {
    .mat-dialog-container {
        padding: 0px !important;
        border-radius: 0px;
    }
    .imgQty {
        @media (min-width: 768px) {
            img {
                width: auto;
                height: 100px;
                cursor: pointer;
            }
        }
        @media (min-width: 576px) and (max-width: 767px) {
            img {
                max-width: 93px;
                cursor: pointer;
            }
        }
    }
    .cartProductDetails {
        padding: 30px 0px 10px 0px;
        cursor: pointer;
        span {
            font-size: 16px;
            color: $theme_text_color;
        }
    }
    .basePrice {
        span {
            font-size: 20px;
            color: var(--accent-color);
        }
    }
    .alert {
        font-size: 75px;
        color: var(--accent-color);
    }
    .outof {
        background: $bg;
    }
    // .cardOver:hover {
    //   transform: scale(1.001);
    //   background-color: rgb(228, 228, 228);
    // }
}

//not found
#notFound {
    .not-found-page {
        h1 {
            font-size: 7rem;
        }
    }
}

//erroer
#error-500 {
    margin-top: 15%;
    width: 100%;
    .content {
        width: 90%;
        max-width: 512px !important;
        .error-code {
            font-size: 112px;
            line-height: 1;
            text-align: center;
            margin-bottom: 16px;
            font-weight: 600;
        }
        .message {
            font-size: 24px;
            text-align: center;
        }
        .sub-message {
            font-size: 17px;
            text-align: center;
            margin: 16px auto 48px auto;
        }
        .report-link {
            text-align: center;
            font-size: 15px;
        }
    }
}

//no item
#noItem {
    .mat-dialog-container {
        padding: 0px !important;
        border-radius: 0px;
        width: 300px;
        height: 225px;
    }
    .alert {
        font-size: 75px;
        color: var(--accent-color);
    }
}

//rating
.rating {
    .ratings {
        color: #fbc02d;
    }
    .ratings-count {
        margin-left: 12px;
        font-weight: 500;
    }
}

//no
#noProducts {
    margin-top: 3%;
    .notFound {
        span {
            font-size: 26px;
            font-weight: 500;
            color: #000;
        }
    }
    .spell {
        span {
            font-size: 22px;
            font-weight: 500;
            color: #878787;
        }
    }
}

.mat-snack-bar-container.success {
    background: #388E3C;
    color: #fff
}

.mat-snack-bar-container.error {
    background: #E53935;
    color: #fff
}

.review-Rating {
    line-height: normal;
    display: inline-block;
    color: #fff;
    padding: 2px 4px 2px 6px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    vertical-align: middle;
    background-color: #388e3c;
}

.border-lb {
    padding: 5px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.6);
    border-right: 1px solid rgba(204, 204, 204, 0.6);
}

.card-2:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    background-color: white;
    cursor: pointer;
}


@media(max-width:480px) {
    .wordWarp {
      hyphens: auto;
    
      font-weight:500;
      color: black;
    }

  }



.price-label{

   color: #212121;
   font-weight: 500;
  }
  .old-price {

    font-weight: 500;
    color: #878787;

  }

  .text-strike{
	text-decoration: line-through;
}

@media (min-width: 0 ) and (max-width:700px) {
    .font-16px {
        font-size: 12px;
    }
}

.sale-label {
    font-size: 12px;
  cursor:text;
    position: absolute;
    z-index: 2;
    top: 2px;
    right: 2px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    padding: 5px;
    font-weight: 400;
    color: #fff;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background: green;
    border-radius: 2px;
  }
  .cursor-text{
    cursor: text;
  }
  @media (min-width: 980px )  {

.cart-items-count {
    position: absolute;
    top: -3px;
    left: 26px;
    background: var(--accent-color);
    height: 18px;
    width: 18px;
    line-height: 18px;
    border-radius: 50%;
    font-size: 11px;
    color: #fff;
}

  }
@media (min-width: 0 ) and (max-width:980px) {
.cart-items-count{
    position: absolute;
    top: -3px;
    left: 24px;
    background: #f44336;
    height: 18px;
    width: 18px;
    line-height: 18px;
    border-radius: 50%;
    font-size: 11px;
    color:#fff;
}
}

.spell{
    text-align: center;
}

.commonHeader{
    padding: 10px;
    background-color: white;
    border-bottom: 1px solid rgba(0,0,0,.1);
}
// rating end
#custom-checkbox {
    // matcheckox
    $md-checkbox-checked-color: hsl(231, 48%, 48%);
    $md-checkbox-border-color: rgba(0, 0, 0, 0.54);
    $md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
    $md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);
    $md-checkbox-margin: 8px 0px;
    $md-checkbox-size: 1.25em;
    $md-checkbox-padding: .25em;
    $md-checkbox-border-width: 2px;
    $md-checkbox-border-radius: 0.125em;
    $md-checkmark-width: 0.125em;
    $md-checkmark-color: #fff;
    $md-checkbox-label-padding: .75em;
    .md-margin {
        margin: $md-checkbox-margin;
    }
    .md-checkbox {
        position: relative;
        text-align: left;
        &.md-checkbox-inline {
            display: inline-block;
        }
        label {
            cursor: pointer;
            display: inline;
            line-height: $md-checkbox-size;
            vertical-align: top;
            clear: both;
            padding-left: 1px;
            &:not(:empty) {
                padding-left: $md-checkbox-label-padding;
            }
            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
            }
            &:before {
                // box
                width: $md-checkbox-size;
                height: $md-checkbox-size;
                background: #fff;
                border: $md-checkbox-border-width solid $md-checkbox-border-color;
                border-radius: $md-checkbox-border-radius;
                cursor: pointer;
                transition: background .3s;
            }
            &:after {
                // checkmark
            }
        }
        input[type="checkbox"] {
            outline: 0;
            visibility: hidden;
            width: $md-checkbox-size;
            margin: 0;
            display: block;
            float: left;
            font-size: inherit;
            &:checked {
                +label:before {
                    background: $md-checkbox-checked-color;
                    border: none;
                }
                +label:after {
                    $md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;
                    transform: translate($md-checkbox-padding, 
                    calc(($md-checkbox-size / 2) - ($md-checkmark-size / 2.6)))
                     rotate(-45deg);
                   width: $md-checkmark-size;
                    height: calc($md-checkmark-size / 2);
                    border: $md-checkmark-width solid $md-checkmark-color;
                    border-top-style: none;
                    border-right-style: none;
                }
            }
            &:disabled {
                +label:before {
                    border-color: $md-checkbox-border-color-disabled;
                }
                &:checked {
                    +label:before {
                        // background: $md-checkbox-checked-color-disabled;
                    }
                }
            }
        }
    }
    // *************************************
    // *************************************
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }
}

.wordBreak{
    word-break: break-word;
}

.wrap
{
  overflow-wrap:anywhere;
}